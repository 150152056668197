// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);

// Offcanvas Funcionality

document.querySelector('.header__navtoggler').addEventListener('click', () => {
  document.body.classList.toggle('activenav');
})

// Navigation Functionality
for (let parentMenuItem of document.querySelectorAll('.header__navigation-mainnav li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth < 1200) {
      e.preventDefault();
      parentMenuItem.classList.toggle('clicked');
    }
  })
}

for (let parentMenuItem of document.querySelectorAll('.header__navigation-mainnav > ul > li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth >= 1200) {
      e.preventDefault();
      if (parentMenuItem.classList.contains('clicked')) {
        parentMenuItem.classList.remove('clicked');
        return;
      }
      for (let parentMenuItem of document.querySelectorAll('.header__navigation-mainnav > ul > li.parent')) {
        parentMenuItem.classList.remove('clicked');
      }
      parentMenuItem.classList.toggle('clicked');
      document.body.classList.toggle('activedropdownnav');
    }
  })
}

document.querySelector('.menubackground').addEventListener('click', () => {
  if (window.innerWidth >= 1200) {
    for (let parentMenuItem of document.querySelectorAll('.header__navigation-mainnav > ul > li.parent')) {
      parentMenuItem.classList.remove('clicked');
    }
    document.body.classList.remove('activedropdownnav');
  }
})


// Accordion

for (let accordionitem of document.querySelectorAll('.accordion__item')) {
  accordionitem.querySelector('.accordion__item-title').addEventListener('click', () => {
    accordionitem.classList.toggle('accordion__item--open')
  })
}

// Article Anchornav Logic

document.addEventListener('DOMContentLoaded', () => {
  if(document.querySelector('.item-page--anchornav')) {
    let headings = {};
    let i = 0;
    let lastMainHeading = false;

    for (let heading of document.querySelectorAll('.com-content-article__body h2, .com-content-article__body h3')) {
      heading.setAttribute('id', 'jumpheading-' + i);

      if (heading.tagName === 'H2') {
        lastMainHeading = i
        headings[i] = {name: heading.textContent, children: {}, hasChildren: false}
      } else {
        headings[lastMainHeading].hasChildren = true
        headings[lastMainHeading].children[i] = {name: heading.textContent}
      }

      i++;
    }
    for (const id in headings) {
      let heading = headings[id]

      let childrenHtml = false

      if (heading.hasChildren) {
        childrenHtml = document.createElement('ul');

        for (const childId in heading.children) {
          let child = heading.children[childId]

          let childLink = document.createElement('a')
          childLink.setAttribute('href', '#jumpheading-' + childId)
          childLink.innerHTML = child.name
          childLink.classList.add('nav-link')

          let childItem = document.createElement('li')
          childItem.appendChild(childLink)

          childrenHtml.appendChild(childItem)
        }
      }

      let headingLink = document.createElement('a')
      headingLink.setAttribute('href', '#jumpheading-' + id)
      headingLink.innerHTML = heading.name
      headingLink.classList.add('nav-link')

      let headingItem = document.createElement('li')

      headingItem.appendChild(headingLink)

      if (childrenHtml) {
        headingItem.appendChild(childrenHtml)
        headingItem.classList.add('parent');
      }

      document.querySelector('.com-content-article__navigation ul').appendChild(headingItem)
    }
  }
})

// Singleblog Layout Highlighting and Nav Behaviour

function isElementInViewport (el) {
  const rect = el.getBoundingClientRect();
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}

// once on pageload

if (document.querySelector('.com-content-article__navigation a')) {
  document.querySelector('.com-content-article__navigation a').classList.add('inviewport');
}

window.addEventListener('scroll', () => {
  let singleblogHeadings = document.querySelectorAll('.com-content-article__body h2, .com-content-article__body h3');

  for (let singleblogHeading of singleblogHeadings) {
    if(isElementInViewport(singleblogHeading) === true) {
      let navitem = document.querySelector('.com-content-article__navigation a[href="#' + singleblogHeading.id + '"]');

      if(navitem) {
        // Remove old active items
        for (let activeItem of document.querySelectorAll('.com-content-article__navigation li a.inviewport')) {
          activeItem.classList.remove('inviewport');
        }

        navitem.classList.add('inviewport');

        if (navitem.parentNode.parentNode.parentNode.nodeName === "LI") {
          navitem.parentNode.parentNode.parentNode.firstChild.classList.add('inviewport')
        }

        return;
      }
    };
  }
})

// Back to Top

document.querySelector('.backtotopbutton').addEventListener('click', () => {
  window.scrollTo(0,0);
})

// Articlenav

if (document.querySelector('.com-content-article__navigationtoggle')) {
  document.querySelector('.com-content-article__navigationtoggle').addEventListener('click', () => {
    document.body.classList.toggle('activearticlenav');
  })
}


document.addEventListener('DOMContentLoaded', () => {
  for (let jumplink of document.querySelectorAll('.com-content-article__navigation a')) {
    jumplink.addEventListener('click', () => {
      if(window.innerWidth < 992) {
        document.body.classList.remove('activearticlenav');
      }
    })
  }
})

// Headerbar-Placement Desktop

let setHeaderbarposition = () => {
  if(window.innerWidth >= 1200) {
    if(window.scrollY <= 55) {
      document.querySelector('.header__logobar').style.transform = 'translateY(' + (55 -  window.scrollY) + 'px)';
    } else {
      document.querySelector('.header__logobar').style.transform = 'translateY(0)';
    }
  }
}

window.addEventListener('scroll', setHeaderbarposition);
document.addEventListener('DOMContentLoaded', setHeaderbarposition)
